<template>
  <div class="terminal">
    <div class="header">
      <!-- <div class="buttons">
        <div class="button red"></div>
        <div class="button yellow"></div>
        <div class="button green"></div>
      </div> -->
      <div class="title">Terminal: wishes.exe</div>
    </div>
    <div class="body">
      <div v-for="(line, index) in lines" :key="index" class="line">
        <span v-if="!line.startsWith('[IMG]')">{{ line }}</span>
        <img v-if="line.startsWith('[IMG]')" v-bind:src="line.replace('[IMG]', '')" class="itemImage" />
      </div>
      <div class="line">
        <span class="commandInput">> {{ command }}</span>
      </div>
    </div>
    <div class="keyboardSpace">
      <div class="keyboardWrapper">
        <div class="simple-keyboard"></div>
      </div>
    </div>
  </div>
  <div class="exit pcOn">
    <span class="exitText pcOn">It's now safe to turn off<br />your computer.</span>
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';
import items from '/app/src/data/items.json'

export default {
  data() {
    return {
      command: "",
      lines: [
        "Welcome!",
        "Type 'help' to see a list of commands."
      ],
      commands: {
        help: () => {
          this.lines.push("These are all commands:")
          Object.keys(this.commands).forEach(element => {
            this.lines.push(element)
          });
        },
        clear: () => {
          this.lines = []
        },
        list: () => {
          this.lines.push("Items:");
          items.forEach((element, i) => {
            this.lines.push(i + ". " + element.title);
          });
        },
        open: (id) => {
          const itemId = id[0]
          if (!itemId) { this.lines.push("Please spcify the ID of the Item you want to view."); return; }
          const item = items[itemId]
          if (!item) { this.lines.push("Item not found!"); return; }
          this.lines.push(item.title + ":");
          let img = require("/app/src/data/" + item.imageName)
          this.lines.push("[IMG]" + img);
          item.Note.forEach(element => {
            this.lines.push(element);
          });
        },
        shutdown: () => {
          document.querySelector(".exit").classList.toggle("pcOn")
          window.setTimeout(() => {
            document.querySelector(".exitText").classList.toggle("pcOn")
          }, 750);
        }
      },
    };
  },
  methods: {
    executeCommand() {
      const command = this.command
      const commandparts = command.trim().toLowerCase().split(" ");
      const commandmain = commandparts[0]
      let commandargs = commandparts
      commandargs.shift()
      if (!this.command) {
        return
      }
      this.lines.push("> " + this.command);
      if (this.commands[commandmain]) {
        this.commands[commandmain](commandargs);
      } else {
        this.lines.push("Command not found");
      }
      this.command = "";
    },
    onChange(input) {
      this.command = input
    },
  },
  mounted() {
    const div = document.createElement('div');
    div.className += "simple-keyboard";
    document.body.appendChild(div);

    const _keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => {
        if (button == "{enter}") {
          this.executeCommand()
          _keyboard.clearInput()
        }

      },
      layout: {
        'default': [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          'q w e r t z u i o p',
          'a s d f g h j k l {enter}',
          'y x c v b n m',
          '{space}'
        ]
      },
      layoutName: "default",
      display: {
        '{bksp}': "←",
        "{enter}": "Enter",
        "{space}": " "
      }
    });
    window.addEventListener("keyup", (e) => {
      switch (e.key.toLowerCase()) {
        case "backspace":
          _keyboard.setInput(_keyboard.getInput().substring(0, _keyboard.getInput().length - 1));
          break;
        case "enter":
          this.executeCommand()
          _keyboard.clearInput()
          break;
        default:
          if (e.key.length == 1)
            _keyboard.setInput(_keyboard.getInput() + e.key)
      }
      this.onChange(_keyboard.getInput());
    })
  }
};
</script>

<style>
.terminal {
  @apply w-full h-screen bg-blue-700 font-C64 text-white flex flex-col
}

.header {
  @apply flex items-center p-3 bg-blue-400
}

.buttons {
  @apply flex mr-2
}

.commandInput {
  @apply w-full after:content-['.'] after:animate-blink after:bg-white after:w-52
}

.button {
  @apply w-3 h-3 rounded-full mr-1
}

.title {
  @apply font-bold
}

.body {
  @apply p-3 h-full flex-col overflow-y-auto
}

.line {
  @apply mb-1 w-full flex
}

.simple-keyboard {
  @apply text-black font-C64
}

.keyboardWrapper {
  @apply w-full lg:w-1/2 relative bottom-0 lg:left-1/2 lg:-translate-x-1/2
}

.keyboardSpace {
  @apply w-full h-max lg:pb-4
}

.itemImage {
  @apply bg-opacity-10 bg-white rounded-xl max-w-72 max-h-72
}

.exit {
  @apply bg-black text-orange-500 font-C64 w-screen h-screen absolute top-0 left-0
}

.exitText {
  @apply left-1/2 -translate-x-1/2 absolute top-1/2 -translate-y-1/2 text-center lg:text-4xl w-full
}

.pcOn {
  @apply hidden
}
</style>